'use client';
import { ROUTES } from '@/lib/constants/routes';
import { useRouter } from 'next/navigation';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useState,
  useContext,
} from 'react';

//TODO: please check if this is still needed
type ContextState = {
  showDrawer: boolean;
  setShowDrawer: Dispatch<SetStateAction<boolean>>;
  onGetStarted: () => void;
  onSignIn: () => void;
};

const AppContext = createContext<ContextState>({
  showDrawer: false,
  setShowDrawer: () => undefined,
  onGetStarted: () => undefined,
  onSignIn: () => undefined,
});

type Props = {
  children: ReactNode;
};

export default function AppContextProvider(props: Props) {
  const router = useRouter();
  const { children } = props;

  const [showDrawer, setShowDrawer] = useState(false);

  function getStartedHandler() {
    router.push(ROUTES.signUp());
  }

  function signInHandler() {
    router.push(ROUTES.auth);
  }

  return (
    <AppContext.Provider
      value={{
        showDrawer,
        setShowDrawer,
        onGetStarted: getStartedHandler,
        onSignIn: signInHandler,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export const useApp = () => {
  return useContext(AppContext);
};
