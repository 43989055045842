export const siteConfig = () => ({
  applicationName: 'Cartinary',
  description: 'Study seamlessly and ease your way to Excellence!',
  twitter: '@cartinary',
  domain: 'https://cartinary.com',
  twitterLink: 'https://x.com/cartinary',
  facebook: 'https://facebook.com/cartinary',
  instagram: 'https://instagram.com/cartinary',
  linkedin: 'https://linkedin.com/company/cartinary',
});
