'use client';
import { LoadingPage } from '@/lib/components/LoadingPage';
import { theme } from '@/lib/theme/theme';
import { ThemeProvider } from '@emotion/react';
import { Box } from '@mui/material';
import { AppRouterCacheProvider } from '@mui/material-nextjs/v13-appRouter';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { ReactNode } from 'react';
import { ToastContainer } from 'react-toastify';
import { persistor, store } from '@/lib/redux/store';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { register } from 'swiper/element/bundle';
import AppContextProvider from '@/lib/contexts/AppContext';
import { AuthContextProvider } from '@/lib/contexts/AuthContext';

// register Swiper custom elements
register();

type Props = {
  children: ReactNode;
};
export default function Providers(props: Props) {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingPage />} persistor={persistor}>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <AppRouterCacheProvider>
            <AppContextProvider>
              <ThemeProvider theme={theme}>
                <AuthContextProvider>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      minHeight: '100vh',
                      overflowX: 'hidden',
                      backgroundColor: (theme) =>
                        theme.palette.background.default,
                    }}
                  >
                    {props.children}
                  </Box>
                </AuthContextProvider>

                <ToastContainer
                  hideProgressBar
                  position="top-center"
                  autoClose={10000}
                  stacked
                />
              </ThemeProvider>
            </AppContextProvider>
          </AppRouterCacheProvider>
        </LocalizationProvider>
      </PersistGate>
    </ReduxProvider>
  );
}
